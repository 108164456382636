import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DownloadIcon from "../../../../assets/images/cargo/chart/download-black.svg";
import CheckIcon from "../../../../assets/images/cargo/chart/check.png";
import { fetchTrendDataForCSV } from "../useTrendCargoData";
import { createCSV, createCsvDownload } from "../../../helpers/csvDownload/createCSV";
import dayjs from "util/dayjs-init.js";
import { useSelector } from "react-redux";
import { selectedVessel } from "../../../model/slice/fleetNavigationDataSlice.js";
import { isChannelTrendFetching } from "../../../model/slice/channelSlice";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys";
import { getCargoDataDuration } from "../../../model/slice/dataDurationSlice";
import { CARGO_PAGES } from "../../../constants/cargo/pages";
import { LOADING_CSV_TAGS, TRANSPORT_CSV_TAGS, UNLOADING_CSV_TAGS } from "../../../constants/cargo/charts";
import { PRESS_TABS } from "../../../constants/cargo/constants";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../../constants/timezone.js";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    width: "135px",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    "& span": {
      marginLeft: "0px",
    },
    "&:hover": {
      background: "#19b2ab",
    },
    "&:disabled": {
      background: "#19b2ab",
      opacity: 0.5,
      color: "#21262f",
      fontColor: "black",
    },
  },
}));

export const CSVDownloadForCargoPage = (props) => {
  const { vesselId, fileType } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isTrendFetching = useSelector(isChannelTrendFetching);
  const [isLoading, setIsLoading] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const cargoDuration = useSelector(getCargoDataDuration);
  const currentSelectedVessel = useSelector(selectedVessel);
  const timezone = useSelector(selectedTimezone);
  const currentVesselName = currentSelectedVessel?.vessel_name;

  const downloadTags = () => {
    switch(fileType){
      case CARGO_PAGES.TRANSPORT:
        return TRANSPORT_CSV_TAGS;
      case PRESS_TABS.LOADING:
        return LOADING_CSV_TAGS;
      case PRESS_TABS.UNLOADING:
        return UNLOADING_CSV_TAGS;
    }
  }

  const handleClick = async () => {
    setIsLoading(true);
    const csvData = await fetchTrendDataForCSV({
      vesselId,
      interval: cargoDuration.interval,
      startDate: cargoDuration.startDate,
      endDate: cargoDuration.endDate,
      tags: downloadTags(),
      timezone,
    });
    const blob = await createCSV(csvData?.CSVTrendData, ",", timezone);
    const filename = `${currentVesselName?.replace(/\s/g, "-") ?? vesselId}_cargo_${fileType}_${(timezone === TIMEZONE.UTC ? dayjs.utc() : dayjs()).format("YYYY-MM-DD-HH-mm-ss")}(${timezone})`;
    createCsvDownload({ blob, filename });
    setIsLoading(false);
    setShowCheck(true);
  };

  useEffect(() => {
    const waitTime = async () => {
      window.setTimeout(() => {
        setShowCheck(false);
      }, 1000);
    };
    if (showCheck) {
      waitTime();
    }
  }, [showCheck]);
  return (
    <Button
      className={classes.submitButton}
      onClick={handleClick}
      disabled={isLoading || isTrendFetching}
    >
      <div style={{ textAlign: "center" }}>
        {
          <>
            {showCheck ? (
              <img src={CheckIcon} style={{ position: "relative", top: "4px" }} />
            ) : isLoading ? (
              <CircularProgress size={10} style={{ marginRight: "5px" }} />
            ) : (
              <img
                src={DownloadIcon}
                style={{ position: "relative", top: "4px", marginRight: "4px" }}
              />
            )}
            <span>{t(localizationKeys.CSVDownload)}</span>
          </>
        }
      </div>
    </Button>
  );
};

CSVDownloadForCargoPage.propTypes = {
  vesselId: PropTypes.string,
  interval: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  fileType: PropTypes.string,
};
