export const releaseNoteAllJp = [
  [
    {
      releaseDate: "2025-01-28T16:00:00",
      updatedDate: "2025-01-28T16:00:00",
      version: "10.2.6",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "改善点",
      descriptions: [
        "CSVファイルに記録される日付のタイムゾーン形式を変更"
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2025-01-17T16:00:00",
      updatedDate: "2025-01-17T16:00:00",
      version: "10.2.5",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "改善点",
      descriptions: [
        "リリースノート（日本語）において、タイトルおよび日付の形式を変更.",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2025-01-16T16:00:00",
      updatedDate: "2025-01-16T16:00:00",
      version: "10.2.4",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "新機能",
      descriptions: [
        "ソフトウェアのバージョンと更新履歴を記載した「更新履歴」のページを追加",
        "トレンドデータのカスタム機能にて、グラフに複合データ（アナログデータ、デジタルデータ）を表示した際のY軸の表示構成を変更",
      ],
      images: [require("./images/v10-2-4_fig1.png").default,],
    },
    {
      title: "改善点",
      descriptions: [
        "トレンドデータのカスタム画面にて、ヘッダーの船舶選択の表示切替に対応（複数隻使用の場合に限る）",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-25T16:00:00",
      updatedDate: "2024-12-25T16:00:00",
      version: "10.2.3",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "改善点",
      descriptions: [
        "トレンドデータにて、デジタルとアナログ両方のチャンネルデータを含むグラフ表示において、ズームインおよびズームアウトの性能を向上",
        "トレンドデータのカスタム機能にて、全てのデジタルチャンネルデータを表示できるように改善",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-19T16:00:00",
      updatedDate: "2024-12-19T16:00:00",
      version: "10.2.2",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "改善点",
      descriptions: [
        "モニタリングページのマイナーなデザイン修正",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-19T16:00:00",
      updatedDate: "2024-12-19T16:00:00",
      version: "10.2.1",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "改善点",
      descriptions: [
        "トレンドデータのカスタム機能にて\n利用可能な全てのチャンネルタイプをチャートに選択・表示できるように改善\n編集機能を改善\nグラフ読み込みのアイコンを追加",
        "トレンドデータのダウンロードにて、CSVファイル内の時刻をUTCおよびLTの形式に対応",
      ],
      images: [],
    },
  ],
  [
    {
      releaseDate: "2024-12-17T16:00:00",
      updatedDate: "2024-12-17T16:00:00",
      version: "10.2.0",
      introduction: "当社は、infoceanus製品のUX(User Experience)向上のため、常に製品改善に取り組んでいます。以下は、connectの最新アップデート情報です。",
      conclusion: "当社は、ユーザー皆様のフィードバックを基に、製品開発・改善を継続的に実施しております。infoceanus製品に関する改善提案やフィードバックがある場合は、connect-support@jrcs.co.jpまでメールでお知らせください。",
    },
    {
      title: "新機能",
      descriptions: [
        "ひとつのユーザーが複数の管理会社の船舶を管理できるようマルチテナント機能を追加",
        '航海データの主機および補機燃料消費量の単位に関する対応\n標準の単位はKLで表示、オプションとしてKG, TON, GALに対応',
        "トレンドデータの閾値通知に関するデザイン更新",
        "ヘッダーのタイムゾーンのデザイン更新\n時刻表示をUTCとLT（現地時刻）で選択できるように対応\nトレンドデータのデータダウンロードは、選択しているタイムゾーンに基づき実施",
        "航海データの真風向と相対風向に関するデザイン更新",
      ],
      images: [],
    },
    {
      title: "改善点",
      descriptions: [
        "アプリケーションのパフォーマンス改善\nチャンネルページのデータ読み込み、応答時間を改善\nトレンド比較ページの読み込み、応答時間を改善\nトレンドデータの読み込み、応答時間を改善",
        "通信状態（オンライン/オフライン）のアラートメールの設定を更新",
        "トレンドデータのカスタム機能にて、編集と削除の機能を改善",
        "トレンドデータのグラフのスナップショットのファイル名に選択されているタイムゾーンの時刻を反映",
        "警報ページにて、アラームのステータスとデータの概要表示を改善",
        "トレンドデータにて、１秒間隔のデータをダウンロードする機能を対応（対応船のみ）",
        "ビジネスパートナー向けのAPIサービスの機能を改善",
      ],
      images: [],
    },
  ],
];